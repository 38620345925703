import * as types from './actionTypes';


let initialState = {
    customerList: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_CUSTOMER_LIST: {
            const customerList = action.request.data.users;
            return {
                ...state,
                customerList: customerList,
            };
        }
        case types._ACTIVE_CUSTOMER: {
            const customer = state.customerList.filter(user => user.email === action.email);
            if (customer.length !== 0)
                customer[0].enabled = 1;
            return {
                ...state
            }
        }
        case types._INACTIVE_CUSTOMER: {
            const customer = state.customerList.filter(user => user.email === action.email);
            if (customer.length !== 0)
                customer[0].enabled = 0;
            return {
                ...state
            }
        }
        default:
            return state;
    }

}
