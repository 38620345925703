import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Fragment, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {errResponse} from "../../helpers/common";
import {getLogList, updateLogType} from "./action";
import {Button} from "@mui/material";
import {CircularProgress} from "@material-ui/core";


export default function LogTable() {
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [reloadList, setReloadList] = React.useState(false);
    const {logList, logType} = useSelector(state => state.log);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getLogList(logType).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            errResponse(err);
        })
        let bot = setInterval(() => {
            setReloadList(true);
            getLogList(logType).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        setTimeout(() => {
                            setReloadList(false);
                        }, 1000)

                    }
                }
            }).catch(err => {
                errResponse(err);
            })
        }, 60000);
        return () => clearInterval(bot);
    }, [logType])

    const handleChangeLogType = (type) => {
        dispatch(updateLogType(type));
    }
    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <div style={{padding: '2rem'}} className='log_table_control'>
                <h4>로그 기록</h4>
                <div>
                    <Button onClick={() => handleChangeLogType('info')} variant={'contained'} color={'primary'}>Info</Button>
                    <Button onClick={() => handleChangeLogType('error')} variant={'contained'} color={'error'}>Error</Button>
                </div>
            </div>

            <div style={{textAlign: 'right', padding: '1rem'}} className='log_loading_wrapper'>
                {reloadList &&
                <Fragment>
                    <CircularProgress/>
                    <small style={{color: '#4d99ff'}}>새로고침중...</small>
                </Fragment>
                }
            </div>

            <TableContainer>
                <Table aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                key={'id'}
                                align={'center'}>
                                ID
                            </TableCell>
                            <TableCell
                                key={'message'}
                                align={'left'}>
                                로그 메세지
                            </TableCell>
                            <TableCell
                                key={'timestamp'}
                                align={'center'}>
                                로그 발생시간
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {logList
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {

                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}
                                              className={logType === 'info' ? 'info_row' : 'error_row'}>
                                        <TableCell align={'center'}>
                                            {row.id}
                                        </TableCell>
                                        <TableCell align={'left'}>
                                            <div className='log_msg'>
                                            {row.message}
                                            </div>
                                        </TableCell>
                                        <TableCell align={'center'}>
                                            {row.timestamp}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={logList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
