import React from 'react';
import PropTypes from 'prop-types';
import {Switch} from "react-router-dom";
import NavBar from "../components/nav/NavBar";
import {RouteIf} from "../hoc/RouteIf";
import Dashboard from "../components/dashboard/Dashboard";
import Ticket from "../components/ticket/Ticket";
import Customer from "../components/customer/Customer";
import TicketDetail from "../components/ticket/detail/TicketDetail";
import WebHostingDashboard from "../components/webHosting/WebHostingDashboard";
import ManagePoint from "../components/managePoint/ManagePoint";
import OptionalService from "../components/option/OptionalService";
import LogDashBoard from "../components/log/LogDashBoard";

const DashboardLayout = ({location}) => {

    return (
        <>
            <NavBar/>
            <Switch>
                <RouteIf exact path="/server" option={true} component={Dashboard}/>
                <RouteIf exact path="/webServer" option={true} component={WebHostingDashboard}/>
                <RouteIf exact path="/support" option={true} component={Ticket}/>
                <RouteIf path="/support/view/:id" option={true} component={TicketDetail}/>
                <RouteIf exact path="/customer" option={true} component={Customer}/>
                <RouteIf exact path="/managePoint" option={true} component={ManagePoint}/>
                <RouteIf exact path="/option" option={true} component={OptionalService}/>
                <RouteIf exact path="/log" option={true} component={LogDashBoard}/>

            </Switch>
            {/*<Footer />*/}
            <div style={{padding: '3rem 0'}}/>

        </>
    );
};

DashboardLayout.propTypes = {location: PropTypes.object.isRequired};

export default DashboardLayout;
