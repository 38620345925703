import * as types from './actionTypes';
import axios from "axios";


export const getLogList = async (type) => {
    const getLogListRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/log/api?level=${type}`)
    return {
        type: types._GET_LOG,
        request: getLogListRequest
    }
}

export const updateLogType = (type) => {
    return {
        type: types._UPDATE_LOG_TYPE,
        logType: type
    }
}
