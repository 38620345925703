import React from "react";
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText } from 'reactstrap';
import WebServerTable from "./WebServerTable";



const WebHostingDashboard = () => {

    return(
        <div className='container-md'>
            {/*<div style={{marginTop : '5rem'}} className='d-flex flex-row'>*/}
            {/*    <Card>*/}
            {/*        <CardHeader>참고용 자료</CardHeader>*/}
            {/*        <CardBody>*/}
            {/*            <CardTitle tag="h5">Special Title Treatment</CardTitle>*/}
            {/*            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
            {/*            <Button>Go somewhere</Button>*/}
            {/*        </CardBody>*/}
            {/*        <CardFooter>Footer</CardFooter>*/}
            {/*    </Card>*/}
            {/*    <Card>*/}
            {/*        <CardHeader>참고용 자료</CardHeader>*/}
            {/*        <CardBody>*/}
            {/*            <CardTitle tag="h5">Special Title Treatment</CardTitle>*/}
            {/*            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
            {/*            <Button>Go somewhere</Button>*/}
            {/*        </CardBody>*/}
            {/*        <CardFooter>Footer</CardFooter>*/}
            {/*    </Card>*/}
            {/*    <Card>*/}
            {/*        <CardHeader>참고용 자료</CardHeader>*/}
            {/*        <CardBody>*/}
            {/*            <CardTitle tag="h5">Special Title Treatment</CardTitle>*/}
            {/*            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
            {/*            <Button>Go somewhere</Button>*/}
            {/*        </CardBody>*/}
            {/*        <CardFooter>Footer</CardFooter>*/}
            {/*    </Card>*/}
            {/*</div>*/}
            <WebServerTable/>
        </div>
    )
}

export default WebHostingDashboard;
