import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmModal from "../common/ConfirmModal";
import {errResponse, notificationAlert} from "../../helpers/common";
import {activeCustomer, inActiveCustomer} from "./action";
import {useDispatch} from "react-redux";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import PointModal from "../point/PointModal";
import Swal from "sweetalert2";


const ITEM_HEIGHT = 48;

export default function CustomerTableMenu({zendeskId, email, userNo}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleActiveCustomer = () => {
        return new Promise((resolve, reject) => {
            activeCustomer(email).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }

    const handleInActiveCustomer = async () => {
        const {value: content} = await Swal.fire({
            title: '정말 유저를 비활성화 시키시겠습니까?',
            input: 'text',
            inputLabel: '비활성화 사유를 입력해주세요.',
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return '사유를 입력하여 주세요.'
                }
            }
        })

        if (content) {
            inActiveCustomer(email, content).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        notificationAlert('알림', `${email} 유저가 비활성화 되었습니다.`);

                    }
                }
            }).catch(err => {
                errResponse(err);
            })

        }
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={handleClose}>
                    <ConfirmModal
                        type={'activeCustomer'}
                        handleEvent={handleActiveCustomer}/>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <Button onClick={handleInActiveCustomer}>유저 비활성화</Button>
                    {/*<ConfirmModal*/}
                    {/*    type={'inActiveCustomer'}*/}
                    {/*    handleEvent={handleInActiveCustomer}/>*/}
                </MenuItem>
                <MenuItem onClick={() => history.push(`/server/?user=${userNo}&email=${email}`)}>
                    <Button>유저 서버 바로가기</Button>
                </MenuItem>
                <MenuItem onClick={() => history.push(`/support/?user=${zendeskId}&email=${email}`)}>
                    <Button>유저 티켓 바로가기</Button>
                </MenuItem>
            </Menu>
        </div>
    );
}
