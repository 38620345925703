import * as types from './actionTypes';
import moment from "moment";

let initialState = {
    botLogs: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._ADD_BOT_LOG : {
            if (state.botLogs.length === 200)
                state.botLogs = [];
            state.botLogs.push(`${moment()} 마이너스 체크를 성공하였습니다.`);
            return {
                ...state,

            }
        }


        default:
            return state;
    }

}
