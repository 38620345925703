export const DelServerTxt = ' 이 서버에 저장된 모든 데이터는 영구적으로 삭제되며 이 서버에 연결된 예약되지 않은 모든 공개 IP 할당은 계정에서 제거됩니다.'

export const ReinstallServerTxt = '서버의 모든 데이터는 영구적으로 손실됩니다!';

export const RestartServerTxt = '이것은 하드 재시작입니다.';

export const StopServerTxt = '이렇게 하면 서버의 전원이 강제로 꺼집니다. 다시 시작 버튼을 통해 서버를 다시 시작해야 합니다.';

export const Ipv6AssignTxt = 'IPv6 네트워크를 할당하면 VPS가 재부팅됩니다.';

export const Ipv4AssignTxt = 'Note: IP 주소를 추가하려면 서버를 다시 시작해야 하며 OS에서 수동으로 구성해야 합니다. 추가 IP는 시간당 $0.003로 청구됩니다.';

export const Ipv4DeleteTxt = '이 작업은 취소할 수 없으며 서버가 하드 다시 시작됩니다. 데이터 손실을 방지하려면 IP를 제거하기 전에 컴퓨터를 정상적으로 중지하는 것이 좋습니다.';

export const activationAutoBackupTxt = '자동 백업을 활성화 어쩌구 하면 걍 좋습니다 무조건 하십쇼';

export const InactivationAutoBackupTxt = '자동 백업을 비활성화 하신 후에는 다시 자동 백업을 활성화 하실 수 없습니다.';

export const RestoreBackupTxt = '현재 서버에 있는 모든 데이터를 덮어씁니다.';

export const RestoreSnapshotTxt = '현재 서버에 있는 데이터를 덮어씁니다.'

export const DelBackupTxt = '이 작업은 취소할 수 없습니다.';

export const UpgradePlanTxt = 'HARD 재시작이 필요합니다. 먼저 스냅샷으로 서버를 백업하는 것이 좋습니다.';

export const ActiveDdos = 'DDOS 보호를 활성화하면 월 $10가 추가됩니다. 적용되는 데 최대 5분이 소요될 수 있습니다.';

export const InActiveDdos = '비활성화 될 경우 DDOS 보호를 다시 활성화할 수 없습니다. 적용되는 데 최대 5분이 소요될 수 있습니다.';

export const AttachIsoTxt = 'ISO 마운트를 하기 위해서는 서버가 재부팅 됩니다.'

export const DetachIsoTxt = '이 프로세스를 완료하기 위해 서버가 재부팅됩니다. 서버가 더 이상 CD의 파일을 사용하고 있지 않은지 확인하십시오.'
