import React, {Fragment} from "react";
import {ServerConfirm} from "../../datas/ServerConfirm";
import {Button} from "@mui/material";
// import {checkPassword} from "../Product/action";
import Swal from "sweetalert2";
import {checkErrorResponse} from "../../helpers/errorCheck";

const ConfirmModal = ({onlyIcon, handleEvent, btnDisabled, type, ...rest}) => {

    // const handleCheckPassword = () => {
    //     Swal.fire({
    //         title: '비밀번호 인증이 필요한 기능 입니다.',
    //         input: 'password',
    //         inputAttributes: {
    //             placeHolder: 'Password',
    //             autocapitalize: 'off'
    //         },
    //         showCancelButton: true,
    //         cancelButtonText: '취소',
    //         confirmButtonText: '인증',
    //         showLoaderOnConfirm: true,
    //         preConfirm: (password) => {
    //             return checkPassword({password}).then(response => {
    //                 if (response) {
    //                     if (response.status === 200) {
    //                         return response.status
    //                     }
    //                 }
    //             }).catch(err => {
    //                 if (err) {
    //                     checkErrorResponse(err);
    //                     return err.response.status
    //                 }
    //             })
    //
    //         },
    //         allowOutsideClick: () => !Swal.isLoading()
    //     }).then(result => {
    //         if (result) {
    //             if (result.isConfirmed && (result.value === 200)) {
    //                 Swal.fire({
    //                     title: ServerConfirm[type].title,
    //                     text: ServerConfirm[type].content,
    //                     showCancelButton: true,
    //                     cancelButtonText: '취소',
    //                     confirmButtonText: ServerConfirm[type].btnTxt,
    //                     showLoaderOnConfirm: true,
    //                     preConfirm: async () => {
    //                         return await handleEvent()
    //                     },
    //                     allowOutsideClick: () => !Swal.isLoading()
    //                 }).then(result => {
    //                     console.log('//result;,',result)
    //                     if (result) {
    //                         if (result.isConfirmed && result.value) {
    //                             Swal.fire({
    //                                 title: ServerConfirm[type].successTitle,
    //                                 confirmButtonText: '닫기'
    //                             })
    //                         }
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // }

    const showModal = () => {
        Swal.fire({
            title: ServerConfirm[type].title,
            text: ServerConfirm[type].content,
            showCancelButton: true,
            cancelButtonText: '취소',
            confirmButtonText: ServerConfirm[type].btnTxt,
            showLoaderOnConfirm: true,
            preConfirm: async () => {
                return await handleEvent()
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then(result => {
            if (result) {
                if (result.isConfirmed && result.value) {
                    Swal.fire({
                        title: ServerConfirm[type].successTitle,
                        confirmButtonText: '닫기'
                    })
                }
            }
        }).catch(err => {
            if (!err) {
                Swal.fire({
                    title: '오류가 발생하였습니다.',
                    confirmButtonText: '닫기'
                })
            }
        })

    }

    return (
        <Fragment>
            <Button {...rest} disabled={btnDisabled} onClick={showModal}>
                {ServerConfirm[type].icon}{' '}
                {!onlyIcon &&
                ServerConfirm[type].toggleBtnTxt
                }
            </Button>
        </Fragment>
    )
}


export default ConfirmModal
