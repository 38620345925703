import React, {useState} from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';
import {Button} from "@material-ui/core";
import {getCustomerList} from "../customer/action";
import {errResponse} from "../../helpers/common";
import {logoutRequest} from "./function";

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const handleLogout = () => {
        logoutRequest().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    window.location.href = '/';
                }
            }
        }).catch(err => {
            errResponse(err);
        })
    }
    return (
        <div>
            <Navbar color="light" light expand="md">
                <div className='container-md'>
                    <NavbarBrand href="/support">SERVER24 Admin Panel</NavbarBrand>
                    <NavbarToggler onClick={toggle}/>
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                                <NavLink href="/support">티켓 관리</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/server">서버 관리</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/webServer">웹 호스팅 관리</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/customer">유저 관리</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/log">로그 관리</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/managePoint">포인트 관리</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="/option">부가 기능</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                    <Button onClick={handleLogout} color={'default'} variant={'contained'}>로그아웃</Button>
                </div>

            </Navbar>
        </div>
    )
}
export default NavBar;
