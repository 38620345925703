import axios from "axios";
import moment from "moment";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import Swal from "sweetalert2";

const Crypto = require('crypto-js');
export const isEmptyArray = (array) => {
    return array.length === 0;
}

const windowHeight = window.innerHeight;

export const navScroll = () => {
    const scrollTop = window.scrollY;
    let alpha = (scrollTop / windowHeight) * 2;
    alpha >= 1 && (alpha = 1);
    if (alpha > 0.7) {
        document.getElementsByClassName('nav_top')[0].style.backgroundColor = 'rgb(255,255,255)';
        document.getElementsByClassName('nav_top')[0].style.color = 'black';
        document.getElementsByClassName('nav_item_title_white')[0].classList.add('nav_item_title_black');
        document.getElementsByClassName('nav_item_title_white')[1].classList.add('nav_item_title_black');
        document.getElementsByClassName('nav_item_title_white')[2].classList.add('nav_item_title_black');
        document.getElementById('signup_btn').classList.add('black_btn');
    } else {
        document.getElementsByClassName('nav_top')[0].style.backgroundColor = 'rgb(12, 30, 103)';
        document.getElementsByClassName('nav_top')[0].style.color = 'white';
        document.getElementsByClassName('nav_item_title_white')[0].classList.remove('nav_item_title_black');
        document.getElementsByClassName('nav_item_title_white')[1].classList.remove('nav_item_title_black');
        document.getElementsByClassName('nav_item_title_white')[2].classList.remove('nav_item_title_black');
        document.getElementById('signup_btn').classList.remove('black_btn');
    }

};


export const sendMsg = async (phone) => {
    return await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/confirm/phone`, {
        phone: phone
    }, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
}
export const isBrowserCheck = () => {
    const agt = navigator.userAgent.toLowerCase();
    if (agt.indexOf("chrome") !== -1) return 'Chrome';
    if (agt.indexOf("opera") !== -1) return 'Opera';
    if (agt.indexOf("staroffice") !== -1) return 'Star Office';
    if (agt.indexOf("webtv") !== -1) return 'WebTV';
    if (agt.indexOf("beonex") !== -1) return 'Beonex';
    if (agt.indexOf("chimera") !== -1) return 'Chimera';
    if (agt.indexOf("netpositive") !== -1) return 'NetPositive';
    if (agt.indexOf("phoenix") !== -1) return 'Phoenix';
    if (agt.indexOf("firefox") !== -1) return 'Firefox';
    if (agt.indexOf("safari") !== -1) return 'Safari';
    if (agt.indexOf("skipstone") !== -1) return 'SkipStone';
    if (agt.indexOf("netscape") !== -1) return 'Netscape';
    if (agt.indexOf("mozilla/5.0") !== -1) return 'Mozilla';
    if (agt.indexOf("msie") !== -1) {
        let rv = -1;
        if (navigator.appName === 'Microsoft Internet Explorer') {
            let ua = navigator.userAgent;
            var re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
            if (re.exec(ua) != null)
                rv = parseFloat(RegExp.$1);
        }
        return 'Internet Explorer ' + rv;
    }
}

export const isLogin = function () {
    const token = localStorage.getItem('auth_login');
    return !!token;
};


export const generateRandomCode = (n) => {
    let str = ''
    for (let i = 0; i < n; i++) {
        str += Math.floor(Math.random() * 10)
    }
    return str
}

export const groupBy = function (data, key) {
    return data.reduce(function (carry, el) {
        let group = el[key];

        if (carry[group] === undefined) {
            carry[group] = []
        }

        carry[group].push(el)
        return carry
    }, {})
}

export const hexToRgb = hexValue => {
    let hex;
    hexValue.indexOf('#') === 0 ? (hex = hexValue.substring(1)) : (hex = hexValue);
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
        hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
    );
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};
export const themeColors = {
    primary: '#0069ff',
    secondary: '#748194',
    success: '#00d27a',
    info: '#27bcfd',
    warning: '#f5803e',
    danger: '#e63757',
    light: '#f9fafd',
    dark: '#0b1727',
    gray: '#66686c'
};

export const colors = [
    '#2c7be5',
    '#00d97e',
    '#e63757',
    '#39afd1',
    '#fd7e14',
    '#02a8b5',
    '#727cf5',
    '#6b5eae',
    '#ff679b',
    '#f6c343'
];
export const agoDay = (agoDay) => {
    return moment().subtract(agoDay, 'days').startOf('day')
}

export const fakeDataMake = () => {
    const fakeData = [];

    for (let i = 0; i <= 28; i++) {
        fakeData.push(i * 500000000000)
    }
    return fakeData;
}
export const fakeDataMake2 = () => {
    const fakeData2 = [];

    for (let i = 0; i <= 28; i++) {
        fakeData2.push(i * 100000000000)
    }
    return fakeData2;
}

export const ago28Days = () => {
    moment.locale('ko');
    const days = [];
    for (let i = 29; i >= 0; i--) {
        days.push(agoDay(i).format('MM/DD dd'))
    }
    return days;
}

export const rgbaColor = (color = colors[0], alpha = 0.5) => `rgba(${hexToRgb(color)},${alpha})`;

const dayToString = (day) => {
    switch (day) {
        case 1:
            return '월'
        case 2:
            return '화'
        case 3:
            return '수'
        case 4:
            return '목'
        case 5:
            return '금'
        case 6:
            return '토'
        default:
            return '일'
    }
}

export const weekString = () => {
    moment.locale('ko');
    return [dayToString(1), dayToString(2), dayToString(3), dayToString(4), dayToString(5), dayToString(6), dayToString(7)]
}

export const oneDayTime = () => {
    const time = [];
    for (let i = 0; i < 24; i++) {
        time.push(i);
    }
    return time;
}

export const oneMonthDay = () => {
    const days = [];
    for (let i = 1; i < 29; i++) {
        days.push(i);
    }
    return days;
}

export const decrypt = (data, key, iv) => {

    key = Crypto.enc.Utf8.parse(key);
    iv = Crypto.enc.Utf8.parse(iv);
    let decrypted = Crypto.AES.decrypt(data, key, {iv: iv});

    return decrypted.toString(Crypto.enc.Utf8);
}

export const getTicketStatus = (status) => {
    const ticketStatusInfo = {
        new: {
            backgroundColor: '#FFFAEE',
            listColor: '#FFCF5F',
            color: '#FFCF5F',
            icon: <HelpCenterOutlinedIcon/>,
            txt: '접수'
        },
        open: {
            backgroundColor: '#E7FBF0',
            listColor: '#20A144',
            color: '#3DCC79',
            icon: <PendingOutlinedIcon/>,
            txt: '처리중'
        },
        pending: {
            backgroundColor: '#E7FBF0',
            listColor: '#20A144',
            color: '#3DCC79',
            icon: <PendingOutlinedIcon/>,
            txt: '처리중'
        },
        solved: {
            backgroundColor: '#E2F4FF',
            listColor: '#0593FF',
            color: '#0593FF',
            icon: <CheckBoxOutlinedIcon/>,
            txt: '처리완료'
        },
        closed: {
            backgroundColor: '#E2F4FF',
            listColor: '#0593FF',
            color: '#0593FF',
            icon: <CheckBoxOutlinedIcon/>,
            txt: '처리완료'
        }
    }
    return ticketStatusInfo[status];
}

export const getNoticeType = (status) => {
    const noticeTypeInfo = {
        news: {
            backgroundColor: '#FFFAEE',
            listColor: '#FFCF5F',
            color: '#FFCF5F',
            txt: '뉴스'
        },
        product: {
            backgroundColor: '#E7FBF0',
            listColor: '#20A144',
            color: '#3DCC79',
            txt: '업데이트'
        },

        bug: {
            backgroundColor: '#E2F4FF',
            listColor: '#0593FF',
            color: '#0593FF',
            txt: '버그 수정'
        },
        emergency: {
            backgroundColor: '#E2F4FF',
            listColor: '#FFE4E6',
            color: '#E11D48',
            icon: <CheckBoxOutlinedIcon/>,
            txt: '긴급'

        }
    }
    return noticeTypeInfo[status];
}


export const notificationAlert = (type, txt) => {
    Swal.fire({
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    })
}


export const getDateString = (date) => {
    return moment(date).format('YYYY년 MM월 DD일')
}

export const errResponse = (err) => {
    if (err){
        if (err.response){
            notificationAlert('오류',err.response.data.message);
        }
    }
}
