import React from "react";
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {RouteIf} from "../hoc/RouteIf";
import Login from "../components/login/Login";
import DashboardLayout from "./DashboardLayout";


const Layout = () => {


    return (
        <Router>
            <Switch>
                <RouteIf exact path="/" option={null} component={Login}/>
                <RouteIf component={DashboardLayout}/>
            </Switch>
        </Router>
    )

}


export default Layout;
