import React, {useState} from "react";
import {Input} from "reactstrap";
import {sendToUserMsg} from "./function";
import {notificationAlert} from "../../helpers/common";
import LoaderButton from "../common/LoaderButton";


const SendMsgAdmin = () => {
    const [phone, setPhone] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [loader, setLoader] = useState(false);
    const handleSendUserMsg = async () => {
        setLoader(true);
        const data = {
            phone,
            title,
            content
        }
        sendToUserMsg(data).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    notificationAlert('알림', '메세지 전송 성공');
                    setLoader(false);
                }
            }
        }).catch(err => {
            setLoader(false);
            if (err) {
                if (err.response) {
                    notificationAlert('오류', err.response.data.message);
                }
            }

        })

    }

    return (
        <div className='send_msg_wrapper'>
            <h4>문자 메세지 전송</h4>
            <div>
                <label>고객 핸드폰 번호</label>
                <Input type={'text'} onChange={event => setPhone(event.target.value)}/>
            </div>
            <div>
                <label>제목</label>
                <Input type={'text'} onChange={event => setTitle(event.target.value)}/>
            </div>
            <div>
                <label>내용</label>
                <Input type={'textarea'} onChange={event => setContent(event.target.value)}/>

            </div>
            <div style={{marginTop: '3rem'}}>
                <LoaderButton loader={loader} onClick={handleSendUserMsg} fullWidth variant={'contained'} color={'primary'}>메세지 전송</LoaderButton>
            </div>
        </div>
    )
}


export default SendMsgAdmin;
