import {combineReducers} from "redux";
import ticket from '../components/ticket/reducer';
import server from '../components/dashboard/reducer';
import customer from '../components/customer/reducer';
import bill from '../components/point/reducer';
import webHosting from '../components/webHosting/reducer';
import bot from '../components/managePoint/reducer';
import log from '../components/log/reducer';

const rootReducer = combineReducers({
    ticket,
    server,
    customer,
    bill,
    webHosting,
    bot,
    log
});

export default rootReducer;
