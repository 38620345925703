import React, {useEffect} from "react";
import PointUsageTable from "./PointUsageTable";
import {getPointUsage} from "./action";
import {useDispatch} from "react-redux";
import {checkErrorResponse} from "../../helpers/errorCheck";


const PointUsage = ({userNo}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        getPointUsage(userNo).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }, [])

    return (
        <div className='point_usage_wrapper'>
            <div className='billing_list_wrapper'>
                <div className='billing_list_title'>
                    <span>포인트 사용 내역</span>
                </div>
                <PointUsageTable loader={true}/>
            </div>
        </div>
    )
}


export default PointUsage;
