import * as types from './actionTypes';
import axios from "axios";


export const getInstanceList = async () => {
    const getInstanceRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv`)
    return {
        type: types._GET_INSTANCE_LIST,
        request: getInstanceRequest
    }
}
export const getVultrDetail = async (id) => {
    const getVultrDetailRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/vultr`)
    return {
        request: getVultrDetailRequest
    }
}

export const stopServer = async (id) => {
    const stopServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/halt`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: stopServerRequest
    }
}

export const restartServer = async (id) => {
    const restartServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/reboot`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: restartServerRequest
    }
}

export const deleteServer = async (id) => {
    const deleteServerRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: deleteServerRequest,
    }
}


export const reinstallServer = async (id) => {
    const reinstallServerRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/reinstall`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: reinstallServerRequest,
    }
}

export const tempDeleteServer = async (id) => {
    const tempDeleteServerRequest = await axios.delete(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/temp`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: tempDeleteServerRequest,
    }
}
export const tempClearDeleteServer = async (id) => {
    const tempClearDeleteServerRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/servermv/instances/${id}/temp`, {}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: tempClearDeleteServerRequest,
    }
}
