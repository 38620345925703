import * as types from './actionTypes';
import axios from "axios";



export const minusCheckRequestBot = async () => {
    const minusCheckRequestBotRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/users/point`)
    return {
        type: types._ADD_BOT_LOG,
        request: minusCheckRequestBotRequest
    }
}
