import React from "react";
import ServerTable from "./ServerTable";
import { Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText } from 'reactstrap';



const Dashboard = () => {

    return(
        <div className='container-md'>
            {/*<div style={{marginTop : '5rem'}} className='d-flex flex-row'>*/}
            {/*    <Card>*/}
            {/*        <CardHeader>참고용 자료</CardHeader>*/}
            {/*        <CardBody>*/}
            {/*            <CardTitle tag="h5">Special Title Treatment</CardTitle>*/}
            {/*            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
            {/*            <Button>Go somewhere</Button>*/}
            {/*        </CardBody>*/}
            {/*        <CardFooter>Footer</CardFooter>*/}
            {/*    </Card>*/}
            {/*    <Card>*/}
            {/*        <CardHeader>참고용 자료</CardHeader>*/}
            {/*        <CardBody>*/}
            {/*            <CardTitle tag="h5">Special Title Treatment</CardTitle>*/}
            {/*            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
            {/*            <Button>Go somewhere</Button>*/}
            {/*        </CardBody>*/}
            {/*        <CardFooter>Footer</CardFooter>*/}
            {/*    </Card>*/}
            {/*    <Card>*/}
            {/*        <CardHeader>참고용 자료</CardHeader>*/}
            {/*        <CardBody>*/}
            {/*            <CardTitle tag="h5">Special Title Treatment</CardTitle>*/}
            {/*            <CardText>With supporting text below as a natural lead-in to additional content.</CardText>*/}
            {/*            <Button>Go somewhere</Button>*/}
            {/*        </CardBody>*/}
            {/*        <CardFooter>Footer</CardFooter>*/}
            {/*    </Card>*/}
            {/*</div>*/}
            <ServerTable/>
        </div>
    )
}

export default Dashboard;
