import * as types from './actionTypes';


let initialState = {
    webHostingList: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_WEB_HOSTING_LIST: {
            const webHostingList = action.request.data.cpanels;
            return {
                ...state,
                webHostingList
            };
        }
        case types._SUSPEND_WEB_HOSTING: {
            const webHostingIndex = state.webHostingList.findIndex(webHosting => webHosting.cu_no === action.cuNo);
            state.webHostingList[webHostingIndex].cu_del_status = 'tDeleted';
            return {
                ...state
            }
        }

        case types._UNSUSPEND_WEB_HOSTING: {
            const webHostingIndex = state.webHostingList.findIndex(webHosting => webHosting.cu_no === action.cuNo);
            state.webHostingList[webHostingIndex].cu_del_status = 'active';
            return {
                ...state
            }
        }


        default:
            return state;
    }

}
