import * as types from './actionTypes';


let initialState = {
    logList: [],
    logType: 'error'
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_LOG: {
            const logList = action.request.data.logs;
            return {
                ...state,
                logList
            };
        }

        case types._UPDATE_LOG_TYPE: {
            return {
                ...state,
                logType: action.logType
            }
        }


        default:
            return state;
    }

}
