import {logoutRequest} from "../components/login/function";
import {notificationAlert} from "./common";

export const checkErrorResponse = (error) => {
    if (error) {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                if (error.response.data.message === '세션이 만료되었습니다.' || error.response.data.message === '비활성화된 계정입니다. 고객센터를 통해 비활성화를 풀어주세요.') {
                    logoutRequest().then(response => {
                        if (response) {
                            if (response.status === 200) {
                                notificationAlert('오류', error.response.data.message);
                                localStorage.removeItem('auth_login');
                                window.location.href = '/';
                            }
                        }

                    }).catch(err => {
                        if (err) {
                            if (err.response) {
                                notificationAlert('오류', err.response.data.message)
                            }
                        }

                    })
                    return;
                }
            }
            console.log(error.response)
            if (error.response.status === 404 && error.response.data.message === '존재하지 않는 인스턴스입니다.') {
                window.history.back();
                notificationAlert('오류', error.response.data.message);
                return;
            }
            notificationAlert('오류', error.response.data.message);
        }
    }
}
