import React from "react";
import LogTable from "./LogTable";


const LogDashBoard = () => {

    return (
        <div className='container-md'>
            <div className='log_dashboard_wrapper'>
                <LogTable/>
            </div>
        </div>
    )
}


export default LogDashBoard;
