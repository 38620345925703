import React from "react";
import {useSelector} from "react-redux";


const BillBox = ({row, data, title}) => {
    const {totalPoint} = useSelector(state => state.bill);
    return (
        <div className='bill_box_wrapper'>
            <div className='bill_box_title'>
                <span className='box_title'>{title}</span>
            </div>
            <div className='bill_box_row'>
                {row.map((txt, index) => {
                    if (txt.key === 'allPoint')
                        return (
                            <div key={index} className='bill_box_item'>
                                <span>{txt.title}</span>
                                <span>{totalPoint.toLocaleString()}P</span>
                            </div>
                        )
                    if (txt.key === 'monthUsePoint' || txt.key === 'todayUsePoint')
                        return (
                            <div key={index} className='bill_box_item'>
                                <span>{txt.title}</span>
                                <span>{data[txt.key]}P</span>
                            </div>
                        )
                    return (
                        <div key={index} className='bill_box_item'>
                            <span>{txt.title}</span>
                            <span>{data[txt.key]}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default BillBox;
