import * as types from './actionTypes';
import {_CLOSE_TICKET} from "./actionTypes";

let initialState = {
    ticketList: [],
    ticketComments: [],
    ticketInfo: {
        fields : []
    },
    ticketUsers: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._INIT_TICEKT_LIST: {
            const ticketList = action.request.data.tickets;
            return {
                ...state,
                ticketList: ticketList,
            };
        }

        case types._ADD_TICKET: {
            const newTicket = action.request.data.ticket;
            return {
                ...state,
                ticketList: [...state.ticketList, newTicket]
            }
        }

        case types._CLOSE_TICKET: {
            state.ticketInfo.status = action.status;
            return {
                ...state,
                ticketInfo: {...state.ticketInfo}
            }
        }

        case types._GET_TICEKT: {
            return {
                ...state,
                ticketComments: action.request.data.comments,
                ticketInfo: action.request.data.ticket,
                ticketUsers: action.request.data.users
            }
        }

        case types._ADD_TICKET_COMMENT: {
            const newComment = action.request.data.comment;
            return {
                ...state,
                ticketComments: [...state.ticketComments,newComment]
            }
        }

        default:
            return state;
    }

}
