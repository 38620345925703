import React, {useState, useEffect, useRef, Fragment} from "react";
import {useParams} from 'react-router-dom';
import {Button} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {TextareaAutosize} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import UserTicket from "./UserTicket";
import {addTicketComment, closeTicket, getTicket, uploadFile} from "../action";
import {useDispatch, useSelector} from "react-redux";
import {isIterableArray, timeToString} from "../../../utils/common";
import InputLabel from "@mui/material/InputLabel";
import {checkErrorResponse} from "../../../helpers/errorCheck";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {getTicketStatus, notificationAlert} from "../../../helpers/common";
import ListSkeleton from "../../common/ListSkeleton";
import PersonIcon from '@mui/icons-material/Person';
import {CircularProgress} from "@material-ui/core";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';


const TicketDetail = () => {
    const {status, subject, fields} = useSelector((state) => state.ticket.ticketInfo);
    const {ticketComments, ticketUsers} = useSelector((state) => state.ticket);
    const fileInputRef = useRef(null);
    const contentInputRef = useRef(null);
    const dispatch = useDispatch();
    let {id} = useParams();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(0);
    const [ticketFile, setTicketFile] = useState(null);
    const [lastMsgUser, setLastMsgUser] = useState({});
    const [ticketStatus, setTicketStatus] = useState({});
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [commentLoader, setCommentLoader] = useState(false);

    const sendTicket = (ticketId, post) => {
        addTicketComment(ticketId, post).then(response => {
            if (response.request.status === 200) {
                dispatch(response);
                contentInputRef.current.value = '';
                fileInputRef.current.value = '';
                setLoading(false);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    function handleSendTicketComment() {
        setLoading(true);
        let post = {
            "content": message,
        }
        if (ticketFile) {
            uploadFile(ticketFile).then(token => {
                if (token) {
                    post.upload = token;
                    sendTicket(id, post);
                }

            }).catch(err => {
                if (err === 'volume Error') {
                    fileInputRef.current.value = '';
                    setLoading(false);
                } else {
                    notificationAlert('오류', err);
                    checkErrorResponse(err);
                }
            })
        } else {
            sendTicket(id, post);
        }

    }

    useEffect(() => {
        setLoading(false)
        getTicket(id).then(response => {
            if (response.request.status === 200) {
                dispatch(response);
                const userId = response.request.data.users.filter(user => !user.agent);
                setUserId(userId[0].id);
                setSkeletonLoader(true);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        let bot = setInterval(() => {
            setCommentLoader(true);
            getTicket(id).then(response => {
                console.log(response)
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        const userId = response.request.data.users.filter(user => !user.agent);
                        setUserId(userId[0].id);
                        setCommentLoader(false);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }, 10000);

        return () => clearInterval(bot);
    }, []);


    useEffect(() => {
        if (isIterableArray(ticketComments)) {
            const author_id = ticketComments[0].author_id;
            const user = ticketUsers.filter(user => user.id === author_id);
            if (isIterableArray(user)) {
                setLastMsgUser(user[0])
            }

        }
    }, [ticketComments, ticketUsers]);

    useEffect(() => {
        if (status) {
            setTicketStatus(getTicketStatus(status));
        }
    }, [status]);


    const handleCloseTicket = (status) => {
        closeTicket(id, status).then(response => {
            if (response.request.status === 200) {
                dispatch(response);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }


    const handleUploadFile = (e) => {
        if (e.target.files) {
            setTicketFile(e.target.files);
        }
    }

    const handleUpdateComment = () => {
        setCommentLoader(true);
        getTicket(id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    const userId = response.request.data.users.filter(user => !user.agent);
                    setUserId(userId[0].id);
                    setCommentLoader(false);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }

    return (
        <Fragment>
            <div className='ticket_detail_top_info'>
                <div className='container-md info_wrapper'>
                    <div className='ticket_info_txt'>
                        <span>티켓 정보</span>
                    </div>
                    {Object.keys(ticketStatus).length !== 0 &&
                    <div className='ticket_info_status' style={{color: ticketStatus.color}}>
                        {ticketStatus.icon}
                        <span>{ticketStatus.txt}</span>
                    </div>
                    }
                    {isIterableArray(ticketComments) && <div className='ticket_info_time' style={{color: 'rgba(0,0,0,0.59)'}}>
                        <AccessTimeIcon fontSize={'small'}/><span>{timeToString(ticketComments[0].created_at)}</span>
                    </div>}
                    <div className='ticket_last_msg' style={{color: '#0593FF'}}>
                        <ChatBubbleOutlineIcon fontSize={'small'}/><span>{lastMsgUser.name}</span>
                    </div>
                    <div className='ticket_last_msg' style={{color: '#0593FF'}}>
                        <PersonIcon fontSize={'small'}/><span>{lastMsgUser.name}</span>
                    </div>
                </div>
                <div className='container-md ticket_option_info'>
                    <div>
                        <span className='fw-bold'>티켓 부가 정보</span>
                    </div>
                    <div className={'ticket_info_data'}>
                        {fields.map((field, index) => {
                            switch (field.id) {
                                case 4413163280399 : {
                                    return (
                                        <span key={index}>인스턴스 ID : {field.value}</span>
                                    )
                                }
                                case 4413163323407 : {
                                    return (
                                        <span key={index}>인스턴스 IP : {field.value}</span>
                                    )
                                }
                                case 4460782940559 : {
                                    return (
                                        <span key={index}>게임 인스턴스 ID : {field.value}</span>
                                    )
                                }

                                case 4536097560463 : {
                                    return (
                                        <span key={index}>웹 호스팅 도메인 : {field.value}</span>
                                    )
                                }
                                default: {
                                    return (
                                        <span key={index}>NULL</span>
                                    )
                                }
                            }
                        })}
                    </div>
                </div>
            </div>
            <div className='ticket_detail_wrapper container-md'>
                <div className='ticket_detail_top'>
                    <div className='ticket_detail_info'>
                        <h4>{subject}</h4>

                    </div>
                    <div className='ticket_detail_btn'>
                        {(status !== 'open' || status !== 'pending') &&
                        <Button style={{marginRight: '2rem'}} variant="contained" onClick={() => handleCloseTicket('pending')} startIcon={<CloseIcon/>}>
                            티켓 처리중
                        </Button>
                        }
                        {(status !== 'solved' || status !== 'closed') &&
                        <Button variant={'contained'} onClick={() => handleCloseTicket('solved')} startIcon={<CloseIcon/>}>
                            티켓 닫기
                        </Button>
                        }
                        <Button className='comment_loader_btn'
                                style={{marginLeft: '1rem'}}
                                variant={'contained'}
                                onClick={handleUpdateComment}
                                color={'primary'}>
                            {commentLoader ?
                                <CircularProgress className='comment_loader_icon'/>
                                :
                                <ReplayOutlinedIcon/>
                            }

                        </Button>
                    </div>
                </div>
                <div className='ticket_detail_content'>
                    <div className='ticket_detail_box'>
                        <div className='ticket_detail_writer_wrapper'>
                            <div className='ticket_detail_writer'>
                                <TextareaAutosize
                                    ref={contentInputRef}
                                    className='ticket_detail_input'
                                    aria-label="maximum height"
                                    placeholder="내용을 입력하세요."
                                    onChange={event => setMessage(event.target.value)}
                                    style={{width: '100%', minHeight: '200px', maxHeight: '700px'}}
                                />
                                <div className='ticket_detail_file'>
                                    <InputLabel>파일 최대 용량 12MB</InputLabel>
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        onChange={(e) => handleUploadFile(e)}
                                    />
                                </div>
                                <LoadingButton
                                    disabled={message.length === 0}
                                    onClick={handleSendTicketComment}
                                    endIcon={<SendIcon/>}
                                    loading={loading}
                                    loadingPosition="end"
                                    variant="contained"
                                >
                                    Send
                                </LoadingButton>
                            </div>

                        </div>
                        <ListSkeleton height={250} type={'rectangular'} top={1} itemNum={3} colNum={12} loading={skeletonLoader}>
                            {isIterableArray(ticketComments) && [...ticketComments].reverse().map((comment, index) => {
                                return (
                                    <UserTicket key={index} comment={comment}/>
                                )

                            })}
                        </ListSkeleton>

                    </div>
                </div>
            </div>
        </Fragment>
    )
}


export default TicketDetail;
