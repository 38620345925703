import axios from "axios";

export const sendToUserMsg = async (post) => {
    const sendToUserMsgRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/auth/phone`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: sendToUserMsgRequest,
    }
}
