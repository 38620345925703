import React, {useEffect, useState} from "react";
import {Box, Button, Tab} from "@material-ui/core";
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import BillInfo from "./BillInfo";
import PointUsage from "./PointUsage";
import {errResponse} from "../../helpers/common";
import {useDispatch} from "react-redux";
import {getPoint} from "./action";
import PointManagement from "./PointManagement";

const PointModal = ({userNo, email}) => {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className='w-100'>
            <Button style={{backgroundColor: '#4e99ff', border: 'none', color: 'white'}} onClick={toggle} className='justify-content-start'>포인트 정보</Button>
            <Modal centered isOpen={modal} toggle={toggle} className={'point_info_modal_wrapper'}>
                <ModalHeader toggle={toggle}>포인트 정보</ModalHeader>
                <ModalBody>
                    <Box sx={{width: '100%', typography: 'body1'}}>
                        <TabContext value={value}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleChange} aria-label="">
                                    <Tab label="결제 내역" value="1"/>
                                    <Tab label="포인트 사용 내역" value="2"/>
                                    <Tab label="포인트 관리" value="3"/>

                                </TabList>
                            </Box>
                            <TabPanel className='tab_panel p-0' value="1"><BillInfo userNo={userNo}/></TabPanel>
                            <TabPanel className='tab_panel p-0' value="2"><PointUsage userNo={userNo}/></TabPanel>
                            <TabPanel className='tab_panel p-0' value="3"><PointManagement userNo={userNo}/></TabPanel>
                        </TabContext>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>닫기</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}


export default PointModal;
