import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmModal from "../common/ConfirmModal";
import {errResponse} from "../../helpers/common";
import {activeCustomer, inActiveCustomer, suspendWebHosting, unSuspendWebHosting} from "./action";
import {useDispatch} from "react-redux";
import {Button} from "@mui/material";
import {useHistory} from "react-router-dom";
import PointModal from "../point/PointModal";


const ITEM_HEIGHT = 48;

export default function WebHostingMoreBtn({cuNo}) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSuspendWebHosting = () => {
        return new Promise((resolve, reject) => {
            suspendWebHosting(cuNo).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }

    const handleUnSuspendWebHosting = () => {
        return new Promise((resolve, reject) => {
            unSuspendWebHosting(cuNo).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls="long-menu"
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem onClick={handleClose}>
                    <ConfirmModal
                        type={'suspend'}
                        handleEvent={handleSuspendWebHosting}/>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ConfirmModal
                        type={'unSuspend'}
                        handleEvent={handleUnSuspendWebHosting}/>
                </MenuItem>
            </Menu>
        </div>
    );
}
