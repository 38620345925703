import React, {useState} from "react";
import {TotalPoint} from "./TotalPoint";
import {useDispatch, useSelector} from "react-redux";
import {Button} from "@mui/material";
import ConfirmModal from "../common/ConfirmModal";
import {errResponse, notificationAlert} from "../../helpers/common";
import {tempDeleteServer} from "../dashboard/action";
import {getPoint, pointAddRequest, pointMinusRequest} from "./action";


const PointManagement = ({userNo}) => {
    const dispatch = useDispatch();
    const {totalPoint} = useSelector(state => state.bill);
    const [addPoint, setAddPoint] = useState('');
    const [minusPoint, setMinusPoint] = useState('');
    const handleAddPoint = () => {
        const point = parseInt(addPoint);
        return new Promise((resolve, reject) => {
            // if (point > 1000000) {
            //     reject(false);
            //     return;
            // }
            pointAddRequest(userNo, point).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }

    const handleMinusPoint = () => {
        const point = parseInt(minusPoint);
        return new Promise((resolve, reject) => {
            if (point > 1000000) {
                reject(false);
                return;
            }
            pointMinusRequest(userNo, point).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }
    const handleGetPoint = () => {
        getPoint(userNo).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            errResponse(err);
        })
    }
    return (
        <div className='point_manage_wrapper'>
            <div style={{color: '#4d99ff', textAlign: 'center', padding: '1rem 3rem'}}>
                <h3>현재 보유 포인트</h3>
                <h2>{parseInt(totalPoint).toLocaleString()}P</h2>
                <Button onClick={handleGetPoint} variant={'contained'}>포인트 새로고침</Button>
            </div>
            <section>
                <h3>포인트 충전하기</h3>
                <input
                    onChange={event => setAddPoint(event.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Point"/>
                <ConfirmModal type={'addPoint'} handleEvent={handleAddPoint}/>
            </section>
            <section>
                <h3>포인트 감면하기</h3>
                <input
                    onChange={event => setMinusPoint(event.target.value)}
                    type="number"
                    className="form-control"
                    placeholder="Point"/>
                <ConfirmModal type={'minusPoint'} handleEvent={handleMinusPoint}/>
            </section>
        </div>
    )
}

export default PointManagement;
