import * as types from './actionTypes';


let initialState = {
    billList: [],
    totalPoint: '0',
    billInfo: {
        account: {
            name: "",
            email: "",
            phone: "",
            seq: ""
        },
        payment: {
            todayUsePoint: 0,
            monthUsePoint: 0,
            recentChargingTime: ""
        },
        paymentRecord: [],
    },
    pointUsageRecord: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._PAYMENT_REQUEST: {
            return {
                ...state
            };
        }
        case types._GET_POINT: {
            return {
                ...state,
                totalPoint: action.request.data.totalPoint
            }
        }

        case types._CHARGING_POINT: {
            const totalPoint = parseInt(state.totalPoint) + ((parseInt(action.point) * 10) / 11);

            return {
                ...state,
                totalPoint: totalPoint.toString()
            }
        }

        case types._GET_BILL_INFO: {
            return {
                ...state,
                billInfo: action.request.data.data
            }
        }

        case types._GET_POINT_USAGE: {
            return {
                ...state,
                pointUsageRecord: action.request.data.data
            }
        }

        default:
            return state;
    }

}
