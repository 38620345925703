import * as types from './actionTypes';
import axios from "axios";


export const getCustomerList = async () => {
    const getCustomerRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/users`)
    return {
        type: types._GET_CUSTOMER_LIST,
        request: getCustomerRequest
    }
}

export const activeCustomer = async (email) => {
    const activeCustomerRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/enabled`, {email}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._ACTIVE_CUSTOMER,
        request: activeCustomerRequest,
        email
    }
}


export const inActiveCustomer = async (email, reason) => {
    const inActiveCustomerRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/auth/account/disabled`, {email, reason}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._INACTIVE_CUSTOMER,
        request: inActiveCustomerRequest,
        email
    }
}
