import React, {useState, useEffect} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {Chip} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {FormGroup, Input} from 'reactstrap';
import {isIterableArray, timeToString} from "../../utils/common";
import ListSkeleton from "../common/ListSkeleton";
import {getTicketStatus} from "../../helpers/common";
import {defaultZendeskIpFieldId} from "../constant/DefaultValue";
import queryString from "query-string";

const columns = [
    {id: 'subject', label: 'SUBJECT', minWidth: 230},
    {id: 'id', align: 'center', label: 'TICKET', minWidth: 30},
    {
        id: 'updated_at',
        label: 'LAST REPLY',
        minWidth: 170,
        align: 'center',
    },
    {
        id: 'status',
        label: 'STATUS',
        minWidth: 170,
        align: 'center',
    }
];


export default function TicketTable({ticketList, toggle, loading}) {
    const history = useHistory();
    const location = useLocation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tickets, setTickets] = useState([]);
    const [status, setStatus] = useState('All');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        let query = {};
        query = queryString.parse(location.search);
        if (Object.keys(query).length !== 0) {

            const userTicketList = ticketList.filter(ticket => ticket.requester_id === parseInt(query.user));
            if (isIterableArray(userTicketList)) {
                setTickets(userTicketList);
                setUserEmail(query.email)
            }
        } else {
            if (ticketList) {
                setTickets(ticketList);
            }
        }

    }, [ticketList, location]);


    const handleChange = (event) => {
        setStatus(event.target.value);
        let newTicketList = [];
        let query = {};
        query = queryString.parse(location.search);
        if (Object.keys(query).length !== 0) {
            newTicketList = ticketList.filter(ticket => ticket.requester_id === parseInt(query.user));
        } else {
            newTicketList = ticketList;
        }
        switch (event.target.value) {
            case 'All':
                setTickets([...newTicketList]);
                break;
            case 'new': {
                setTickets(newTicketList.filter(ticket => ticket.status === 'new'));
                break;
            }
            case 'open': {
                const openList = newTicketList.filter(ticket => ticket.status === 'open');
                const pendingList = newTicketList.filter(ticket => ticket.status === 'pending');
                setTickets([...openList, ...pendingList]);
                break;
            }
            case 'solved': {
                const solvedList = newTicketList.filter(ticket => ticket.status === 'solved');
                const closedList = newTicketList.filter(ticket => ticket.status === 'closed');
                setTickets([...solvedList, ...closedList]);
                break;
            }
            default :
                setTickets([...newTicketList]);
                break;
        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };
    const handleSearchTicket = (input) => {
        let newTicketLists = [];
        let query = {};
        query = queryString.parse(location.search);
        if (Object.keys(query).length !== 0) {
            newTicketLists = ticketList.filter(ticket => ticket.requester_id === parseInt(query.user));
        } else {
            newTicketLists = ticketList;
        }
        const newTicketList = newTicketLists.filter((ticket) =>
            ticket.subject.toLowerCase().includes(input));
        setTickets(newTicketList);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div>
            {userEmail.length !== 0 &&
            <h4>{userEmail}님이 생성하신 티켓</h4>}
            <div className='ticket_filter_wrapper'>
                <FormGroup>
                    <Input type="select"
                           onChange={handleChange}
                           value={status}
                           name="select"
                           id="ticket_status_sel">
                        <option value={'All'}>전체</option>
                        <option value={'new'}>접수</option>
                        <option value={'open'}>처리중</option>
                        <option value={'solved'}>처리완료</option>
                    </Input>

                </FormGroup>
                <div className="ticket_search_wrapper">
                    <input
                        onChange={event => handleSearchTicket(event.target.value)}
                        type="text" className="form-control"
                        placeholder="Search..."/>
                </div>
            </div>
            <ListSkeleton loading={loading}>
                {isIterableArray(tickets) ?
                    <TableContainer>
                        <Table className='ticket_table' style={{backgroundColor: '#F7F9FA'}} aria-label=" table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth, borderBottom: 'none', color: '#8297AA'}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {
                                [...tickets]
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (<TableBody key={index}>
                                                <TableRow className='ticket_row_wrapper' onClick={() => history.push(`/support/view/${row.id}`)} style={{height: '80px', backgroundColor: '#F7F9FA', cursor: 'pointer'}} role="checkbox" tabIndex={-1} key={index}>
                                                    {columns.map((column) => {
                                                        let value = row[column.id];
                                                        if (column.id === 'subject') {
                                                            const instanceIdLabel = row.fields.filter(instance => instance.id === defaultZendeskIpFieldId);
                                                            return (
                                                                <TableCell style={{borderBottom: 'none', padding: 0}} key={column.id} align={column.align}>
                                                                    <div className={'table_item'} style={{backgroundColor: 'white'}}>
                                                                        <span className='d-block fw-bold'>{value}</span>
                                                                        {isIterableArray(instanceIdLabel) &&
                                                                        <small style={{color: '#7b7b7b'}}>{instanceIdLabel[0].value}</small>}
                                                                    </div>
                                                                </TableCell>
                                                            )
                                                        }
                                                        if (column.id === 'updated_at') value = timeToString(value);
                                                        const ticketStatus = getTicketStatus(value);
                                                        return (
                                                            <TableCell style={{borderBottom: 'none', padding: 0}} key={column.id} align={column.align}>
                                                                <div className={column.id !== 'status' ? 'table_item' : 'table_status'} style={{backgroundColor: 'white'}}>
                                                                    {column.id === 'status' ? <Chip className='status_chip' label={ticketStatus.txt} style={{fontWeight: 'bold', color: ticketStatus.listColor, backgroundColor: ticketStatus.backgroundColor}}/>
                                                                        :
                                                                        value}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            </TableBody>
                                        );
                                    })
                            }
                        </Table>
                    </TableContainer>
                    :
                    <h2>티켓이 없음</h2>
                }
            </ListSkeleton>
            <TablePagination
                labelRowsPerPage={'페이지당 목록 수'}
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={tickets.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
}
