import React from "react";
import leftAngle from "../../assets/img/login/left_angle.png";
import centerAngle from "../../assets/img/login/center_angle.png";
import rightAngle from "../../assets/img/login/right_angle.png";
import LoginMainLogo from "../../assets/img/login/login_main_logo.png";


const GradientBack = ({children, boxSize}) => {


    return (
        <div className='gradient_wrapper'>
            <img id='gradient_main_logo' src={LoginMainLogo}/>
            <div className='gradient_box' style={{width: `${boxSize}px`}}>
                {children}
            </div>
            <img id='left_angle' src={leftAngle}/>
            <img id='center_angle' src={centerAngle}/>
            <img id='right_angle' src={rightAngle}/>
        </div>
    )
}

export default GradientBack


GradientBack.defaultProps = {
    boxSize: 450
}
