import * as types from './actionTypes';
import axios from "axios";
import {notificationAlert} from "../../helpers/common";

export const initTicketList = async () => {
    const initTicketRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/zendesk/ticket`)
    return {
        type: types._INIT_TICEKT_LIST,
        request: initTicketRequest
    }
}
export const addTicket = async (post) => {
    const addTicketRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/zendesk/ticket`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._ADD_TICKET,
        request: addTicketRequest
    }
}

export const closeTicket = async (id, status) => {
    const closeTicketRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/zendesk/ticket/${id}`, {status}, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._CLOSE_TICKET,
        request: closeTicketRequest,
        status
    }
}
export const addTicketComment = async (id, post) => {
    const addTicketCommentRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/zendesk/ticket/${id}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    })
    return {
        type: types._ADD_TICKET_COMMENT,
        request: addTicketCommentRequest
    }
}

export const getTicket = async (id) => {
    const getTicketRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/zendesk/ticket/${id}`);
    return {
        type: types._GET_TICEKT,
        request: getTicketRequest
    }
}


export const uploadTicketFile = async (fileName, file) => {
    const uploadTicketFileRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/zendesk/ticket/upload/${fileName}`, file, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        request: uploadTicketFileRequest
    }
}


export const uploadFile = (ticketFile) => {
    return new Promise((resolve, reject) => {


        const file = ticketFile;
        if (file[0] === undefined) {
            return;
        }
        if (file[0].size > 1024 * 1024 * 12) {
            notificationAlert('알림', '12MB 이하 파일만 등록할 수 있습니다.\n\n' + '현재파일 용량 : ' + (Math.round(file[0].size / 1024 / 1024 * 100) / 100) + 'MB');
            reject('volume Error');
            return;
        }

        const formData = new FormData();
        formData.append(
            "file",
            file[0],
            file[0].name
        );
        uploadTicketFile(file[0].name, formData).then(response => {
            if (response.request.status === 200) {
                const imgToken = response.request.data.file.token;
                resolve(imgToken)

            }
        }).catch(err => {
            reject(err);

        })
    })
}
