import React, {useState, useEffect} from "react";
import TicketTable from "./TicketTable";
import {Button} from "@mui/material";
import {initTicketList} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {checkErrorResponse} from "../../helpers/errorCheck";
import {CircularProgress} from "@material-ui/core";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';


const Ticket = () => {
    const dispatch = useDispatch();
    const ticketList = useSelector((state) => state.ticket.ticketList);
    const [openModal, setOpenModal] = useState(false);
    const [listLoading, setListLoading] = useState(false);
    const [commentLoader, setCommentLoader] = useState(false);


    const toggle = () => {
        setOpenModal(!openModal)
    }
    useEffect(() => {
        initTicketList().then(result => {
            if (result.request.status === 200) {
                dispatch(result);
                setListLoading(true);
            }
        }).catch(err => {
            checkErrorResponse(err);
        })

        let bot = setInterval(() => {
            setCommentLoader(true);
            initTicketList().then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                        setCommentLoader(false);
                    }
                }
            }).catch(err => {
                checkErrorResponse(err);
            })
        }, 10000);

        return () => clearInterval(bot);
    }, [])

    const handleUpdateComment = () => {
        setCommentLoader(true);
        initTicketList().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setCommentLoader(false);
                }
            }
        }).catch(err => {
            checkErrorResponse(err);
        })
    }


    return (
        <div className='container-md ticket_wrapper'>
            <div className='ticket_top'>
                <h2>Support {`(${ticketList.length})`}</h2>
                <div>
                    <Button onClick={() => window.open('https://server24.zendesk.com/access/unauthenticated')} variant={'contained'} color={'primary'}>젠데스크 바로가기</Button>
                    <Button className='comment_loader_btn'
                            style={{marginLeft: '1rem'}}
                            variant={'contained'}
                            onClick={handleUpdateComment}
                            color={'primary'}>
                        {commentLoader ?
                            <CircularProgress className='comment_loader_icon'/>
                            :
                            <ReplayOutlinedIcon/>
                        }
                    </Button>
                </div>
            </div>
            <TicketTable toggle={toggle} loading={listLoading} ticketList={ticketList}/>


        </div>
    )
}


export default Ticket;
