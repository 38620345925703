import {activationAutoBackupTxt, ActiveDdos, AttachIsoTxt, DelBackupTxt, DelServerTxt, DetachIsoTxt, InactivationAutoBackupTxt, InActiveDdos, Ipv4AssignTxt, Ipv4DeleteTxt, Ipv6AssignTxt, ReinstallServerTxt, RestartServerTxt, RestoreBackupTxt, RestoreSnapshotTxt, StopServerTxt, UpgradePlanTxt} from "../components/constant/Popover";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DiscFullIcon from "@mui/icons-material/DiscFull";
import DeleteIcon from "@mui/icons-material/Delete";
import BackupIcon from '@mui/icons-material/Backup';
import RestoreIcon from '@mui/icons-material/Restore';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DeveloperBoardOffIcon from '@mui/icons-material/DeveloperBoardOff';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SecurityIcon from '@mui/icons-material/Security';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';


export const ServerConfirm = {
    restart: {
        icon: <RestartAltIcon/>,
        title: '정말 서버를 재시작 하시겠습니까? 이 작업은 취소할 수 없으며, 서버의 전원이 강제로 재시작됩니다.',
        successTitle: '서버가 성공적으로 재시작되었습니다.',
        content: RestartServerTxt,
        btnTxt: '재시작',
        toggleBtnTxt: '서버 재시작',
        password: false,
    },
    stop: {
        icon: <PowerSettingsNewIcon/>,
        title: '정말 서버를 종료 하시겠습니까? 이 작업은 취소할 수 없으며, 서버의 전원이 강제로 종료됩니다.',
        successTitle: '서버가 성공적으로 종료되었습니다.',
        content: StopServerTxt,
        btnTxt: '종료',
        toggleBtnTxt: '서버 종료',
        password: false,
    },
    reinstall: {
        icon: <DiscFullIcon/>,
        title: '정말 서버를 초기 상태로 재설치 하시겠습니까? 이 작업은 취소할 수 없으며, 서버가 초기 상태로 재설치됩니다.',
        successTitle: '현재 서버가 초기 상태로 재설치되고 있습니다. 이 작업은 약 10~120분가량 소요될 수 있습니다.',
        content: ReinstallServerTxt,
        btnTxt: '초기화',
        toggleBtnTxt: '서버 초기화',
        password: false,
    },
    delete: {
        icon: <DeleteIcon/>,
        title: '정말 서버를 삭제 하시겠습니까? 이 작업은 취소할 수 없으며, 서버와 내부 데이터가 영구적으로 삭제됩니다.',
        successTitle: '서버 삭제에 성공하였습니다. 실제 삭제까지는 약 1분정도 소요될 수 있습니다.',
        content: DelServerTxt,
        btnTxt: '삭제',
        toggleBtnTxt: '서버 삭제',
        password: false,
    },
    deleteBackup: {
        icon: <DeleteIcon/>,
        title: '정말 백업을 삭제 하시겠습니까? 이 작업은 취소할 수 없으며, 백업 데이터가 영구적으로 삭제됩니다.',
        successTitle: '백업 삭제에 성공하였습니다.',
        content: DelBackupTxt,
        btnTxt: '삭제',
        toggleBtnTxt: '백업 삭제',
        password: true,
    },
    enableBackup: {
        icon: <BackupIcon/>,
        title: '자동백업을 활성화 하시겠습니까?',
        successTitle: '자동백업 활성화에 성공하였습니다. 설정된 예약 일정에 따라 자동 백업이 진행됩니다.',
        content: activationAutoBackupTxt,
        btnTxt: '활성화',
        toggleBtnTxt: '자동 백업 활성화',
        password: false,
    },
    disableBackup: {
        icon: <BackupIcon/>,
        title: '정말 자동백업을 비활성화 하시겠습니까? 이 작업은 취소할 수 없으며, 기존 백업 데이터가 수일 내 영구적으로 삭제됩니다.',
        successTitle: '자동백업 비활성화에 성공하였습니다.',
        content: InactivationAutoBackupTxt,
        btnTxt: '비활성화',
        toggleBtnTxt: '자동 백업 비활성화',
        password: true,
    },
    restoreBackup: {
        icon: <RestoreIcon/>,
        title: '정말 백업으로 서버를 복원 하시겠습니까? 이 작업은 취소할 수 없으며, 현재의 서버 데이터가 선택하신 기존 백업 데이터로 대체됩니다.',
        successTitle: '현재 서버 데이터가 선택하신 백업 데이터에서 복원되고 있습니다. 이 작업은 약 10~120분가량 소요될 수 있습니다.',
        content: RestoreBackupTxt,
        btnTxt: '복원',
        toggleBtnTxt: '백업 복원',
        password: true,
    },
    restoreSnapshot: {
        icon: <RestoreIcon/>,
        title: '정말 스냅샷으로 서버를 복원 하시겠습니까? 이 작업은 취소할 수 없으며, 현재의 서버 데이터가 선택하신 기존 스냅샷 데이터로 대체됩니다.',
        successTitle: '현재 서버 데이터가 선택하신 스냅샷 데이터에서 복원되고 있습니다. 이 작업은 약 10~120분가량 소요될 수 있습니다.',
        content: RestoreSnapshotTxt,
        btnTxt: '복원',
        toggleBtnTxt: '스냅샷 복원',
        password: true,
    },
    deleteIpv4: {
        icon: <DeleteIcon/>,
        title: '정말 연결된 IP를 삭제 하시겠습니까? 이 작업은 취소할 수 없으며, 서버의 네트워크가 순단되거나 전원이 강제로 재시작될 수 있습니다.',
        successTitle: 'IP 삭제에 성공하였습니다. 서버의 네트워크가 순단되거나 전원이 강제로 재시작될 수 있습니다.',
        content: Ipv4DeleteTxt,
        btnTxt: '삭제',
        toggleBtnTxt: 'IP 삭제',
        password: true,
    },
    addIpv4: {
        icon: <ControlPointIcon/>,
        title: '정말 IPv4 1개를 추가로 할당하시겠습니까? 이 작업은 취소할 수 없으며, 추후 IP 회수 요청시 서버의 네트워크가 순단되거나 전원이 강제로 재시작될 수 있습니다.',
        successTitle: '신규 IPv4 추가 할당에 성공하였습니다. 추가된 IP를 서버 내부에 직접 할당하셔야 합니다. 자세한 방법은 FAQ 혹은 문서를 참고하시거나 티켓으로 문의주시기 바랍니다.',
        content: Ipv4AssignTxt,
        btnTxt: '추가',
        toggleBtnTxt: 'IPv4 추가',
        password: true,
    },
    enableIpv6: {
        icon: <ControlPointIcon/>,
        title: '정말 IPv6 1개를 추가로 할당하시겠습니까? 이 작업은 취소할 수 없으며, 추후 IP 회수 요청시 서버의 네트워크가 순단되거나 전원이 강제로 재시작될 수 있습니다.',
        successTitle: '신규 IPv6 추가 할당에 성공하였습니다. 추가된 IP를 서버 내부에 직접 할당하셔야 합니다. 자세한 방법은 FAQ 혹은 문서를 참고하시거나 고객센터로 문의주시기 바랍니다.',
        content: Ipv6AssignTxt,
        btnTxt: '추가',
        toggleBtnTxt: 'IPv6 추가',
        password: true,
    },
    isoMount: {
        icon: <DeveloperBoardIcon/>,
        title: '해당 ISO 파일을 서버에 연결하시겠습니까? 추후 ISO 분리를 위해서는 서버 재시작이 필요합니다.',
        successTitle: 'ISO 파일을 서버에 성공적으로 연결하였습니다.',
        content: AttachIsoTxt,
        btnTxt: '연결',
        toggleBtnTxt: 'ISO 연결',
        password: true,
    },
    isoUnMount: {
        icon: <DeveloperBoardOffIcon/>,
        title: '연결된 ISO 파일의 연결을 해제하시겠습니까? 이 작업은 취소할 수 없으며, ISO 분리를 위하여 서버의 전원이 재시작됩니다.',
        successTitle: 'ISO 파일 연결 해제에 성공하였습니다. 잠시 후 서버의 전원이 재시작됩니다.',
        content: DetachIsoTxt,
        btnTxt: '해제',
        toggleBtnTxt: 'ISO 연결 해제',
        password: true,
    },
    changePlan: {
        icon: <AttachMoneyIcon/>,
        title: '정말 요금제를 변경하시겠습니까? 이 작업은 취소할 수 없으며, 성능 변경을 위하여 서버의 전원이 즉시 재시작됩니다.',
        successTitle: '성공적으로 요금제를 변경하였습니다. 시스템 내 디스크 공간이 확보되지 않았다면 고객센터로 문의주시거나 직접 확보해주시기 바랍니다.',
        content: UpgradePlanTxt,
        btnTxt: '변경',
        toggleBtnTxt: '요금제 변경',
        password: false,
    },
    enableDdos: {
        icon: <SecurityIcon/>,
        title: '10G DDos 방어를 활성화 하시겠습니까? 적용까지는 약 1~10분 정도 소요됩니다.',
        successTitle: '10G DDos 방어를 성공적으로 활성화하였습니다. 적용까지는 약 1~10분 정도 소요되며, 현재 서버로 10G 이상의 공격이 발생하면 전체 네트워크를 보호하기 위해 일시적으로 Null Route 처리될 수 있습니다.',
        content: ActiveDdos,
        btnTxt: '활성화',
        toggleBtnTxt: '10G DDos 방어 활성화',
        password: true,
    },
    disableDdos: {
        icon: <RemoveModeratorIcon/>,
        title: '10G DDos 방어를 비활성화 하시겠습니까? 적용까지는 약 1~10분 정도 소요됩니다.',
        successTitle: '10G DDos 방어를 성공적으로 비활성화하였습니다. 적용까지는 약 1~10분 정도 소요되며, 이후 DDos 공격이 발생하면 전체 네트워크를 보호하기 위해 서버가 Null Route 처리될 수 있습니다. 공격이 소강되면 Null Route 조치는 자동으로 해제됩니다.',
        content: InActiveDdos,
        btnTxt: '비활성화',
        toggleBtnTxt: '10G DDos 방어 비활성화화',
        password: true,
    },
    tempDelete: {
        icon: <RemoveModeratorIcon/>,
        title: '임시로 인스턴스를 삭제하시겠습니까?',
        successTitle: '임시로 인스턴스를 삭제하였습니다.',
        content: '임시로 고객의 인스턴스를 삭제하게 됩니다.',
        btnTxt: '임시 삭제',
        toggleBtnTxt: '임시 삭제',
        password: true,
    },
    tempClearDelete: {
        icon: <RemoveModeratorIcon/>,
        title: '인스턴스 임시 삭제를 해제하시겠습니까?',
        successTitle: '인스턴스 임시 삭제가 해제되었습니다.',
        content: '임시로 고객의 인스턴스 삭제를 해제하게 됩니다.',
        btnTxt: '임시 삭제 해제',
        toggleBtnTxt: '임시 삭제 해제',
        password: true,
    },
    activeCustomer: {
        icon: <RemoveModeratorIcon/>,
        title: '정말 유저를 활성화 하시겠습니까?',
        successTitle: '유저를 활성화 하였습니다.',
        content: '유저를 활성화 시킵니다..',
        btnTxt: '유저 활성화',
        toggleBtnTxt: '유저 활성화',
        password: true,
    },
    inActiveCustomer: {
        icon: <RemoveModeratorIcon/>,
        title: '정말 유저를 비활성화 하시겠습니까?',
        successTitle: '유저를 비활성화 하였습니다.',
        content: '유저를 비활성화 시킵니다..',
        btnTxt: '유저 비활성화',
        toggleBtnTxt: '유저 비활성화',
        password: true,
    },
    addPoint: {
        icon: <AddBoxOutlinedIcon/>,
        title: '정말 유저 포인트를 충전하시겠습니까?',
        successTitle: '유저를 포인트를 충전 하였습니다.',
        content: '유저의 포인트를 충전합니다.',
        btnTxt: '유저 포인트 충전',
        toggleBtnTxt: '유저 포인트 충전',
        password: true,
    },
    minusPoint: {
        icon: <IndeterminateCheckBoxOutlinedIcon/>,
        title: '정말 유저 포인트를 감면하시겠습니까?',
        successTitle: '유저를 포인트를 감면 하였습니다.',
        content: '유저의 포인트를 감면합니다.',
        btnTxt: '유저 포인트 감면',
        toggleBtnTxt: '유저 포인트 감면',
        password: true,
    },
    suspend: {
        icon: <CancelPresentationIcon/>,
        title: '정말 유저 cPanel 서버를 정지하시겠습니까?',
        successTitle: '유저 cPanel 서버를 정지 하였습니다.',
        content: 'cPanel 서버를 정지합니다.',
        btnTxt: 'cPanel 서버 정지',
        toggleBtnTxt: 'cPanel 서버 정지',
        password: true,
    },
    unSuspend: {
        icon: <PlayCircleOutlineIcon/>,
        title: '정말 유저 cPanel 서버 정지를 해제 하시겠습니까?',
        successTitle: '유저 cPanel 서버 정지를 해제 하였습니다.',
        content: 'cPanel 서버 정지를 해제합니다.',
        btnTxt: 'cPanel 서버 정지해제',
        toggleBtnTxt: 'cPanel 서버 정지해제',
        password: true,
    },
}
