import axios from "axios";
import * as types from "./actionTypes";
import {_CHARGING_POINT, _GET_BILL_INFO} from "./actionTypes";

export const paymentRequest = async (payType, paymentKey, post) => {
    const paymentApiRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/payment/approval/${payType}/${paymentKey}`, post, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        type: types._PAYMENT_REQUEST,
        request: paymentApiRequest,
        data: post
    }
}

export const pointAddRequest = async (userNo, point) => {
    const pointAddAxiosRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/users/${userNo}/point/add`, {point}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: pointAddAxiosRequest,
    }
}

export const pointMinusRequest = async (userNo, point) => {
    const pointMinusAxiosRequest = await axios.post(`https://${process.env.REACT_APP_API_SERVER}/users/${userNo}/point/minus`, {point}, {
        headers: {
            "Content-Type": 'application/json',
        }
    });

    return {
        request: pointMinusAxiosRequest,
    }
}


export const getPoint = async (userNo) => {
    const getPointRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/users/${userNo}/point`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_POINT,
        request: getPointRequest,
    }
}

export const chargingPointRequest = (point) => {

    return {
        type: types._CHARGING_POINT,
        point: point,
    }
}

export const getBillInfo = async (userNo) => {
    const getBillInfoRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/users/${userNo}/point/info`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_BILL_INFO,
        request: getBillInfoRequest,
    }
}

export const getPointUsage = async (userNo) => {
    const getPointUsageRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/users/${userNo}/point/usage`, {
        headers: {
            "Content-Type": 'application/json',
        }
    });
    return {
        type: types._GET_POINT_USAGE,
        request: getPointUsageRequest,
    }
}
