import React from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import Layout from "./layouts/Layout";
import axios from "axios";
import store from './store';
import {Provider} from 'react-redux';

axios.defaults.withCredentials = true;

function App() {
    return (
        <Provider store={store}>
            <Router>
                <Layout/>
            </Router>
        </Provider>
    );
}

export default App;
