import * as types from './actionTypes';
import axios from "axios";


export const getWebHostingList = async () => {
    const getWebHostingListRequest = await axios.get(`https://${process.env.REACT_APP_API_SERVER}/cpanel`)
    return {
        type: types._GET_WEB_HOSTING_LIST,
        request: getWebHostingListRequest
    }
}

export const suspendWebHosting = async (cuNo) => {
    const suspendWebHostingListRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/cpanel/${cuNo}/suspend`)
    return {
        type: types._SUSPEND_WEB_HOSTING,
        request: suspendWebHostingListRequest,
        cuNo
    }
}


export const unSuspendWebHosting = async (cuNo) => {
    const unSuspendWebHostingRequest = await axios.patch(`https://${process.env.REACT_APP_API_SERVER}/cpanel/${cuNo}/unsuspend`)
    return {
        type: types._UNSUSPEND_WEB_HOSTING,
        request: unSuspendWebHostingRequest,
        cuNo
    }
}

