import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPoint} from "./action";
import {errResponse} from "../../helpers/common";
import {Button} from "@mui/material";
import {Spinner} from "reactstrap";


export const TotalPoint = ({userNo}) => {
    const dispatch = useDispatch();
    const {totalPoint} = useSelector(state => state.bill);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        getPoint(userNo).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response)
                }
            }
        }).catch(err => {
            errResponse(err);
        })
    }, []);

    const handleGetPoint = () => {
        setLoader(true);
        getPoint(userNo).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                    setLoader(false);
                }
            }
        }).catch(err => {
            setLoader(false);
            errResponse(err);
        })
    }
    return (
        <Fragment>
            {loader ?
                <Spinner size={'lg'}
                         aria-hidden="true"
                         as="span"
                         animation="border"
                         color='primary'
                         variant="primary">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
                :
                <Fragment>
                    <h3>현재 보유 포인트</h3>
                    <h2>{parseInt(totalPoint).toLocaleString()}P</h2>
                    <Button onClick={handleGetPoint} variant={'contained'}>포인트 새로고침</Button>
                </Fragment>
            }
        </Fragment>
    )
}
