import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ServerDetailModal from "./ServerDetailModal";
import {useEffect, useState} from "react";
import {getInstanceList} from "./action";
import {useDispatch, useSelector} from "react-redux";
import {errResponse} from "../../helpers/common";
import {Input} from 'reactstrap';
import {Button} from "@mui/material";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4d99ff',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
const getServiceType = (type) => {
    switch (type) {
        case 'vhf' :
            return '고성능 가상 서버'
        case 'vc2' :
            return '일반 가상 서버'
        case 'vbm' :
            return '베어메탈 서버'
        case 'vdc' :
            return '단독 가상 서버'
    }
}
export default function ServerTable() {
    const dispatch = useDispatch();
    const location = useLocation();
    const {instanceList} = useSelector(state => state.server);
    const [instanceState, setInstanceState] = useState([]);
    const [searchType, setSearchType] = useState('name');
    const [userEmail, setUserEmail] = useState('');

    useEffect(() => {
        getInstanceList().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            errResponse(err);
        })
    }, []);

    useEffect(() => {

        if (instanceList.length !== 0) {
            let filterInstanceList = [];
            let query = {};
            query = queryString.parse(location.search);
            if (Object.keys(query).length !== 0) {
                filterInstanceList = instanceList.filter(instance => instance.vi_user_no === parseInt(query.user));
                if (query.email) {
                    setUserEmail(query.email);
                }
            } else {
                filterInstanceList = instanceList;
            }
            setInstanceState(filterInstanceList);
        }
    }, [instanceList])

    const handleSearchServer = (input) => {
        let filterInstanceList = [];
        let query = {};
        query = queryString.parse(location.search);
        if (Object.keys(query).length !== 0) {
            filterInstanceList = instanceList.filter(instance => instance.vi_user_no === parseInt(query.user));
        } else {
            filterInstanceList = instanceList;
        }
        if (input.length === 0) {
            setInstanceState(filterInstanceList);
            return;
        }
        const newInstanceList = filterInstanceList.filter((instance) =>
            instance[searchType].toLowerCase().includes(input));
        setInstanceState(newInstanceList);
    }
    return (
        <TableContainer sx={{marginTop: '5rem'}} component={Paper}>
            <div style={{padding: '1rem'}} className='d-flex flex-row justify-content-between'>
                <h4>고객 서버 목록</h4>
                <Button onClick={() => window.open('https://https://my.vultr.com/')} variant={'contained'} color={'primary'}>벌쳐 바로가기</Button>
            </div>
            {userEmail.length !== 0 &&
            <h4 style={{marginLeft: '1rem'}}>{userEmail}님이 생성하신 서버</h4>}
            <div className="server_search_wrapper">
                <Input onChange={event => setSearchType(event.target.value)} type="select">
                    <option value={'name'}>고객명</option>
                    <option value={'vi_id'}>인스턴스ID</option>
                    <option value={'vi_main_ip'}>IPv4</option>
                </Input>
                <input
                    onChange={event => handleSearchServer(event.target.value)}
                    type="text" className="form-control"
                    placeholder="Search..."/>
            </div>
            <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell align="left">인스턴스 ID</StyledTableCell>
                        <StyledTableCell align="left">서비스 종류</StyledTableCell>
                        <StyledTableCell align="left">고객명</StyledTableCell>
                        <StyledTableCell align="left">IPv4</StyledTableCell>
                        <StyledTableCell align="left">요금제</StyledTableCell>
                        <StyledTableCell align="left">서버 상태</StyledTableCell>
                        <StyledTableCell align="right">서버 제어</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {instanceState.sort(function (a, b) { // 내림차순
                        if (a?.vi_del_status && !b?.vi_del_status) {
                            return 1;
                        }
                        if (!a?.vi_del_status && b?.vi_del_status) {
                            return -1;
                        }
                        return 0;
                    }).filter(instance => instance.vi_status !== 'deleted').map((instance) => (
                        <StyledTableRow className={instance.vi_del_status ? 'del_background' : 'active_background'} key={instance.vi_no}>
                            <StyledTableCell>{instance.vi_no}</StyledTableCell>
                            <StyledTableCell align="left">{instance.vi_id}</StyledTableCell>
                            <StyledTableCell align="left">{getServiceType(instance.vp_type)}</StyledTableCell>
                            <StyledTableCell align="left">{instance.name}</StyledTableCell>
                            <StyledTableCell align="left">{instance.vi_main_ip}</StyledTableCell>
                            <StyledTableCell align="left">{instance.vi_plan}</StyledTableCell>
                            <StyledTableCell align="left">{instance.vi_del_status ? '삭제됨' : '활성중'}</StyledTableCell>
                            <StyledTableCell align="right"><ServerDetailModal instanceData={instance}/></StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
