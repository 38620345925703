import React, {useEffect, useState} from 'react';
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, ButtonToggle} from 'reactstrap';
import {Chip} from "@material-ui/core";
import InstanceStatus from "./InstanceStatus";
import ConfirmModal from "../common/ConfirmModal";
import {errResponse} from "../../helpers/common";
import {deleteServer, getVultrDetail, reinstallServer, restartServer, stopServer, tempClearDeleteServer, tempDeleteServer} from "./action";

const ServerDetailModal = ({instanceData}) => {
    const [modal, setModal] = useState(false);
    const [id, setId] = useState('');
    const [vultrDetail, setVultrDetail] = useState({});
    const toggle = () => setModal(!modal);
    const closeBtn = <Button className="close" onClick={toggle}>&times;</Button>;
    useEffect(() => {
        if (instanceData) {
            setId(instanceData.vi_id);
        }
    }, [instanceData])

    const handleServerStop = () => {
        return new Promise((resolve, reject) => {
            stopServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    };

    const handleServerRestart = () => {
        return new Promise((resolve, reject) => {
            restartServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    };

    const handleServerDelete = () => {
        return new Promise((resolve, reject) => {
            deleteServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }
    const handleServerReinstall = () => {
        return new Promise((resolve, reject) => {
            reinstallServer(id).then(response => {
                if (response) {
                    if (response.request.status === 202) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }

    const handleServerTempDelete = () => {
        return new Promise((resolve, reject) => {
            tempDeleteServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {
                errResponse(err);
                reject(false);
            })
        })
    }

    const handleServerTempClearDelete = () => {
        return new Promise((resolve, reject) => {
            tempClearDeleteServer(id).then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        resolve(true);
                    }
                }
            }).catch(err => {

                errResponse(err);
                reject(false);
            })
        })
    }
    const handleGetVultr = () => {
        getVultrDetail(id).then(response => {
            if (response) {
                if (response.request.status === 200) {
                    setVultrDetail(response.request.data.instance)
                }
            }
        }).catch(err => {
            errResponse(err);
        })
    }
    return (
        <div>
            <Button style={{backgroundColor: '#4e99ff', border: 'none'}} onClick={toggle}>서버 제어</Button>
            <Modal centered isOpen={modal} toggle={toggle} className='server_detail_modal'>
                <ModalHeader toggle={toggle} close={closeBtn}>서버 제어</ModalHeader>
                <ModalBody>
                    <section>
                        <h4>요금제 정보</h4>
                        <Row className='server_detail_plan'>
                            <Col className='plan_item' xs={5}><span>{instanceData.vi_plan}</span> <Chip label="요금제" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vi_os}</span> <Chip label="OS" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vp_vcpu_count}CORE</span> <Chip label="CPU" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vp_bandwidth}GB</span> <Chip label="Traffic" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vp_ram}MB</span> <Chip label="Memory" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vi_main_ip}</span> <Chip label="MAIN-IP" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vp_disk}GB</span> <Chip label="Disk" color="primary"/></Col>
                            <Col className='plan_item' xs={5}><span>{instanceData.vi_plan}</span> <Chip label="내용2" color="primary"/></Col>
                        </Row>
                    </section>
                    <section>
                        <h4>서버 상태</h4>
                        <div className='server_status_wrapper mt-5 d-flex flex-row justify-content-start'>
                            <InstanceStatus
                                vi_server_status={instanceData.vi_server_status}
                                vi_status={instanceData.vi_status}
                                vi_power_status={instanceData.vi_power_status}/>
                            <Chip label={`vi_server_status : ${instanceData.vi_server_status}`} color={'primary'}/>
                            <Chip label={`vi_status : ${instanceData.vi_status}`} color={'primary'}/>
                            <Chip label={`vi_power_status : ${instanceData.vi_power_status}`} color={'primary'}/>
                        </div>
                    </section>
                    <section>
                        <h4>서버 제어</h4>
                        <div className='server_control_btn_wrapper'>
                            <ConfirmModal handleEvent={handleServerRestart} type={'restart'}/>
                            <ConfirmModal handleEvent={handleServerStop} type={'stop'}/>
                            <ConfirmModal handleEvent={handleServerReinstall} type={'reinstall'}/>
                            <ConfirmModal handleEvent={handleServerDelete} type={'delete'}/>
                            <ConfirmModal handleEvent={handleServerTempDelete} type={'tempDelete'}/>
                            <ConfirmModal handleEvent={handleServerTempClearDelete} type={'tempClearDelete'}/>
                            {/*<ButtonToggle color="primary">서버 재시작</ButtonToggle>{' '}*/}
                            {/*<ButtonToggle color="secondary">서버 정지</ButtonToggle>{' '}*/}
                            {/*<ButtonToggle color="success">서버 재설치</ButtonToggle>{' '}*/}
                            {/*<ButtonToggle color="info">서버 삭제</ButtonToggle>{' '}*/}
                        </div>
                    </section>
                    <section>
                        <h4>벌쳐 정보 가져오기</h4>
                        <Button color="secondary" onClick={handleGetVultr}>정보 가져오기</Button>
                        <div className='vultr_detail_txt'>
                            {Object.keys(vultrDetail).map(key => {
                                return (
                                    <span key={key}>{`${key} : ${vultrDetail[key]}`}</span>
                                )
                            })}
                        </div>
                    </section>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>닫기</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ServerDetailModal;
