import React, {useEffect, useState} from "react";
import {Button} from "@mui/material";
import {Col, Row} from "reactstrap";
import {getWebHostingList} from "../webHosting/action";
import {errResponse} from "../../helpers/common";
import {minusCheckRequestBot} from "./action";
import {useDispatch, useSelector} from "react-redux";


const ManagePoint = () => {
    const dispatch = useDispatch();
    const [botStatus, setBotStatus] = useState(false);
    const {botLogs} = useSelector(state => state.bot);
    useEffect(() => {
        let bot = setInterval(() => {
            minusCheckRequestBot().then(response => {
                if (response) {
                    if (response.request.status === 200) {
                        dispatch(response);
                    }
                }
            }).catch(err => {
                errResponse(err);
            })
            if (!botStatus) {
                clearInterval(bot);
            }
        }, 60000)

        return () => clearInterval(bot);
    }, [botStatus])
    return (
        <div className='container-md'>
            <div className='minus_point_check_bot_wrapper'>
                <Row>
                    <Col xs={4} className='bot_controller'>
                        <h4>{botStatus ? '봇 상태 : ON' : ' 봇 상태 : OFF'}</h4>
                        <Button onClick={() => setBotStatus(true)} variant={'contained'} color={'primary'}>마이너스 체크 시작</Button>
                        <Button onClick={() => setBotStatus(false)} variant={'contained'} color={'primary'}>마이너스 체크 정지</Button>
                    </Col>
                    <Col xs={8}>
                        <div className='bot_log_box'>
                            <div className='bot_log_item'>
                                {botLogs.map((log, index) => {
                                    return (
                                        <p key={index}>{log}</p>
                                    )
                                })}

                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}

export default ManagePoint;
