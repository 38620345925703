import React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Fragment} from "react";
import {useSelector} from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ListSkeleton from "../common/ListSkeleton";


const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'white',
        color: '#8297AA',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#F4F8FA',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function PointUsageTable({loader}) {
    const {pointUsageRecord} = useSelector(state => state.bill);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Fragment>
            <ListSkeleton height={350} type={'rectangular'} itemNum={1} colNum={12} loading={loader}>
                <Table sx={{minWidth: 700}} aria-label="bill table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">분류</StyledTableCell>
                            <StyledTableCell align="left">서비스 항목</StyledTableCell>
                            <StyledTableCell align="right">차감 포인트</StyledTableCell>
                            <StyledTableCell align="center">차감일</StyledTableCell>
                            <StyledTableCell align="center">비고</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pointUsageRecord.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((payment, index) => (
                                <StyledTableRow key={index}>
                                    <StyledTableCell align="center">{payment.type}</StyledTableCell>
                                    <StyledTableCell align="left">
                                        <span className='d-block'>{`${payment.name}`}</span>
                                        <span>{payment.subName}</span>


                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {payment.ipCost + payment.ddosCost + payment.dayCost
                                        + payment.bandwidthCost + payment.backupCost + payment.snapshotCost}P
                                    </StyledTableCell>
                                    <StyledTableCell align="center">{payment.time}</StyledTableCell>
                                    <StyledTableCell align="center">
                                        {payment.more
                                            .split('\n')
                                            .map((line, index) => {
                                                return (
                                                    <span key={index}>{line}<br/></span>
                                                )
                                            })}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    labelRowsPerPage={'페이지당 목록 수'}
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={pointUsageRecord.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </ListSkeleton>
        </Fragment>
    );
}
