import * as React from 'react';
import {styled} from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CustomerDetailModal from "./CustomerDetailModal";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getInstanceList} from "../dashboard/action";
import {errResponse} from "../../helpers/common";
import {getCustomerList} from "./action";
import CustomerTableMenu from "./CustomerTableMenu";
import {Input} from "reactstrap";
import PointModal from "../point/PointModal";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4d99ff',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const customerStatus = (enabled, tryCnt) => {
    if (enabled === 1) {
        return '활동중'
    } else {
        if (tryCnt === 10) {
            return '셀프 비활성화'
        }
        if (tryCnt < 5) return '운영자 비활성화'
        if (tryCnt === 5) return '로그인 실패 비활성화'
    }

}


export default function CustomerTable() {
    const dispatch = useDispatch();
    const {customerList} = useSelector(state => state.customer);
    const [customerState, setCustomerState] = useState([]);
    const [searchType, setSearchType] = useState('email');
    useEffect(() => {
        getCustomerList().then(response => {
            if (response) {
                if (response.request.status === 200) {
                    dispatch(response);
                }
            }
        }).catch(err => {
            errResponse(err);
        })
    }, [])

    useEffect(() => {
        if (customerList.length !== 0) {
            setCustomerState(customerList);
        }
    }, [customerList])

    const handleSearchServer = (input) => {
        if (input.length === 0) {
            setCustomerState(customerList);
            return;
        }
        const newCustomerList = customerList.filter((instance) => {
            if (instance[searchType])
                return instance[searchType].toLowerCase().includes(input)
        });
        setCustomerState(newCustomerList);
    }

    return (
        <TableContainer sx={{marginTop: '5rem'}} component={Paper}>
            <div style={{padding: '1rem'}}>
                <h4>고객 목록</h4>
            </div>
            <div className="server_search_wrapper">
                <Input onChange={event => setSearchType(event.target.value)} type="select">
                    <option value={'email'}>고객 이메일</option>
                    <option value={'name'}>고객명</option>
                    <option value={'phone'}>고객 전화번호</option>
                </Input>
                <input
                    onChange={event => handleSearchServer(event.target.value)}
                    type="text" className="form-control"
                    placeholder="Search..."/>
            </div>
            <Table sx={{minWidth: 700}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>No</StyledTableCell>
                        <StyledTableCell align="right">고객명</StyledTableCell>
                        <StyledTableCell align="right">고객 이메일</StyledTableCell>
                        <StyledTableCell align="right">고객 전화번호</StyledTableCell>
                        <StyledTableCell align="left">가입 날짜</StyledTableCell>
                        <StyledTableCell align="left">마지막 로그인</StyledTableCell>
                        <StyledTableCell align="center">활성화 상태</StyledTableCell>
                        <StyledTableCell align="center">작업</StyledTableCell>
                        <StyledTableCell align="center">포인트 정보</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customerState.map((customer) => (
                        <StyledTableRow key={customer.no}>
                            <StyledTableCell>{customer.no}</StyledTableCell>
                            <StyledTableCell align="right">{customer.name}</StyledTableCell>
                            <StyledTableCell align="right">{customer.email}</StyledTableCell>
                            <StyledTableCell align="right">{customer.phone}</StyledTableCell>
                            <StyledTableCell align="left">{customer.create_time}</StyledTableCell>
                            <StyledTableCell align="left">{customer.last_login_time}</StyledTableCell>
                            <StyledTableCell align="center">{customerStatus(customer.enabled, customer.try_cnt)}</StyledTableCell>
                            <StyledTableCell align="center"><CustomerTableMenu zendeskId={customer.zendesk_id} userNo={customer.no} email={customer.email}/></StyledTableCell>
                            <StyledTableCell align="center"><PointModal userNo={customer.no} email={customer.email}/></StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
