import * as types from './actionTypes';
import {_CLOSE_TICKET, _GET_INSTANCE_LIST} from "./actionTypes";

let initialState = {
    instanceList: []
}


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case types._GET_INSTANCE_LIST: {
            const instanceList = action.request.data.instances;
            return {
                ...state,
                instanceList: instanceList,
            };
        }


        default:
            return state;
    }

}
