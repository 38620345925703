import React, {useEffect, useState} from "react";
import {Form, Label, FormGroup, Input, Button, Row, Col} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import passwordIcon from '../../assets/img/login/password.svg';
import GradientBack from "../common/GradientBack";
import {useForm} from "react-hook-form";
import classNames from "classnames";
import {loginRequest} from "./function";
import {errResponse, generateRandomCode, notificationAlert, sendMsg} from "../../helpers/common";
import LoaderButton from "../common/LoaderButton";
import emailIcon from "../../assets/img/login/email.svg";
import {setCookie} from "../../utils/common";
import Swal from "sweetalert2";
import sha256 from 'sha256';


const Login = () => {
    const history = useHistory();
    const [authPhone, setAuthPhone] = useState(false);
    const [loader, setLoader] = useState(false);
    const {register, handleSubmit, errors} = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    useEffect(() => {
        Swal.fire({
            title: '핸드폰 번호를 입력하여 주세요.',
            input: 'text',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: false,
            confirmButtonText: '인증하기',
            showLoaderOnConfirm: true,
            preConfirm: (phoneNum) => {
                return sendMsg(phoneNum).then(response => {
                    if (response) {
                        if (response.status === 200) {
                            return response
                        }
                    }
                }).catch(err => {
                    if (err) {
                        errResponse(err);
                        window.location.href = '/';
                    }
                })

            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed && result.value.status === 200) {
                Swal.fire({
                    title: '인증 번호를 입력하여 주세요.',
                    input: 'text',
                    inputAttributes: {
                        autocapitalize: 'off'
                    },
                    showCancelButton: true,
                    confirmButtonText: '인증하기',
                    showLoaderOnConfirm: true,
                    preConfirm: (authNum) => {
                        const enCryptoCode = sha256(authNum + process.env.REACT_APP_PHONE_KEY);
                        if (enCryptoCode === result.value.data.certNum) {
                            //성공
                            setAuthPhone(true);
                            Swal.fire('인증 성공!')
                        } else {
                            notificationAlert('오류', '잘못된 인증번호 입니다.');
                            window.location.href = '/';
                        }
                    },
                    allowOutsideClick: false
                })
            }
        })
    }, [])
    const onSubmitLogin = data => {
        if (!authPhone) {
            notificationAlert('오류', '핸드폰 인증 완료후 로그인 바랍니다.');
            window.location.href = '/';
            return;
        }
        setLoader(true);
        if (data) {
            const post = {
                email: data.loginEmail,
                password: data.loginPwd,
            }

            loginRequest(post).then(response => {
                if (response) {
                    if (response.status === 200) {
                        setLoader(false);
                        localStorage.setItem('auth_login', generateRandomCode(16));
                        setCookie(`last_login_username=${data.loginEmail}`);
                        history.push('/support');
                    }
                }
            }).catch(err => {
                setLoader(false);
                if (err) {
                    if (err.response) {
                        notificationAlert('오류', err.response.data.message);
                        window.location.href = '/';
                    }
                }

            })
        }
    };


    return (
        <GradientBack boxSize={450}>
            <div className='login_wrapper'>
                <div className='login_box'>
                    <div className='login_form_wrapper'>
                        <h2>관리자 로그인</h2>
                        <Form onSubmit={handleSubmit(onSubmitLogin)}>
                            <FormGroup>
                                <Label className='login_label'>이메일</Label>
                                <div className='login_input_wrapper'>
                                    <img src={emailIcon}/>
                                    <Input
                                        placeholder={'이메일 주소를 입력해 주세요.'}
                                        id='loginEmail'
                                        name='loginEmail'
                                        type="text"
                                        maxLength={50}
                                        errors={errors}
                                        innerRef={register()}
                                        className={classNames({'border-danger': errors.loginEmail})}
                                    />
                                </div>
                                {errors.loginEmail && <span className="text-danger fs--1">{errors.loginEmail.message}</span>}
                            </FormGroup>
                            <FormGroup>
                                <Label className='login_label'>비밀번호</Label>
                                <div className='login_input_wrapper'>
                                    <img src={passwordIcon}/>
                                    <Input
                                        placeholder={'비밀번호를 입력해 주세요.'}
                                        type="password"
                                        id='loginPwd'
                                        name='loginPwd'
                                        autoComplete='new-password'
                                        innerRef={register({
                                            required: '비밀번호는 반드시 입력되어야 합니다',
                                        })}
                                        errors={errors}
                                        className={classNames({'border-danger': errors.loginPwd})}
                                    />
                                </div>
                                {errors.loginPwd && <span className="text-danger fs--1">{errors.loginPwd.message}</span>}
                            </FormGroup>

                            <FormGroup>
                                <LoaderButton type='submit' color='primary' block className="mt-3 login_btn" loader={loader}>
                                    Login
                                </LoaderButton>
                            </FormGroup>


                        </Form>
                    </div>
                </div>

            </div>
        </GradientBack>
    )
}


export default Login;
